import classNames from 'classnames';
import styles from './RequiredInput.module.scss';

export function RequiredInput(props: {
    valid: boolean,
    required?: boolean,
    [key: string]: any,
    className?: string,
}) {
    const {valid, className, required=true, ...validHtmlProps} = props;

    const inputClassName = classNames({
        [className || ""]: true,
        [styles.input]: required,
        [styles.inputValid]: required && valid,
    })

    return <input {...validHtmlProps} className={inputClassName} />
}
