import {observer} from "mobx-react";
import {SetStateAction, useState} from "react";
import className from "classnames";
import styles from "./styles/AccordionTerrace.module.scss";
import inputStyles from "../../SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput.module.scss";
import editStyles from "../../SchoolBlocks/styles/EditPane.module.scss";
import {RequiredInput} from "../../SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput";
import MarkdownEditor from "../../../editors/MarkdownEditor";

const AccordionItemEditor = observer((props: {
    blockObj: IAccordionTerrace,
    index: number,
    item: IAccordionItem,
    handleSave: () => void,
    handleCancel: () => void,
    accordionList: IAccordionItem[]
    setAccordionList: SetStateAction<any>
}) => {

    const [title, setTitle] = useState(props.item.title);
    const [message, setMessage] = useState(props.item.content);

    const titleIsValid = !!(title?.length > 0);
    const messageIsValid = !!(message?.length > 0);


    const markdownEditorClassName = className({
        [styles.markdownEditor]: true,
        [inputStyles.div]: true,
        [inputStyles.divValid]: messageIsValid,
    })
    const handleSubmit = () => {
        if (props.index===props.blockObj.json_data.accordion?.length) {
            const list = props.accordionList
            list.push({"title": title, "content": message});
            props.setAccordionList(list)
        }
        else {
            props.accordionList[props.index] = {"title": title, "content": message}
        }
        props.handleSave();
    }
    const handleDelete = () => {
        props.accordionList.splice(props.index, 1)
        props.handleSave();
    }

    const handleMessageChange = (value) => {
        setMessage(value);
    };
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    return <>
        <div className={editStyles.inputGroup}>
            <label>Title</label>
            <div>
                <RequiredInput
                    title="Message Title"
                    className="form-control"
                    type="text"
                    onChange={handleTitleChange}
                    value={title}
                    valid={titleIsValid}
                />
            </div>
        </div>

        <div className={editStyles.inputGroup}>
            <label>Message</label>
            <MarkdownEditor
                handleTextChange={handleMessageChange}
                value={message}
                editorClassName={markdownEditorClassName}
                key={props.index}
                className={styles.editor}
            />
        </div>
        <button type="button"
                className={`${styles.controlButton} ${styles.saveButton} btn ${!titleIsValid || !messageIsValid ? styles.disabled : ''}`}
                disabled={!titleIsValid || !messageIsValid}
                onClick={handleSubmit}
        >
            Save Accordion Item
        </button>
        <button type="button"
                className={`${styles.controlButton} ${styles.closeButton} btn`}
                disabled={false}
                onClick={props.handleCancel}
        >
            Close
        </button>
        {props.accordionList && props.index !== props.accordionList.length && <button type="button"
                                                                                      className={`${styles.controlButton} ${styles.deleteButton} btn`}
                                                                                      disabled={false}
                                                                                      onClick={handleDelete}
        >
            Delete
        </button>}


    </>
});

export default AccordionItemEditor;
